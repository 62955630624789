import axios from 'axios'
import { HTTP } from '../helpers/http-common'
const API = process.env.VUE_APP_BACKEND_URL
//const API = 'https://jsonplaceholder.typicode.com/todos'
const createTaskAPI="http://127.0.0.1:8000/project/tasks/create"
const createReport = '/project/report/create'

export default {
  getUsers () {
    return axios.get(API)
  },
  postUsers (todo) {
    return axios.post(API, todo)
  },
  getProjects () {
    // return axios.get(API);
  },
  getProjectsAsync () {
    // return axios.get(API);
  },
  createTask (data) {
    return axios.post(createTaskAPI, data, {
      headers: {
      'Content-Type': 'application/json'
      }
    })
  },
  createReport(data) {
    return HTTP.post(createReport, data, { headers: {
        'Content-type': 'application/json',
      }
    })
  },

}
