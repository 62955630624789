<template>
  <div class="vue-tempalte add-report">
    <div class="d-sm-flex align-items-center justify-content-between mb-1">
      <div class="col">
        <h1 class="h3 mb-0 text-gray-800">Roles</h1>
      </div>
    </div><br>

    <form @submit.prevent="createRole()" style="background-color:#ffffff" class="px-2 py-4 shadow">
      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label class="mandatory">Role name:</label>
        </div>
        <input type="text" class="form-control" v-model="role.name" :state="null"
          required placeholder="Enter the role" oninvalid="this.setCustomValidity('Please enter the role name')"
          oninput="this.setCustomValidity('')">
      </div><br>

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label class="mandatory">Role description</label>
        </div>
        <textarea id="textarea-formatter" rows="2" wrap="soft" class="form-control" v-model="role.description"
          :state="null" required placeholder="Enter description"
          oninvalid="this.setCustomValidity('Please enter description')"
          oninput="this.setCustomValidity('')"></textarea>
      </div><br>

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label class="mandatory">Permission</label>
        </div>
        <div class="col p-0 m-0">
          <multiselect v-model="value" :options="permissions" :multiple="true" label="permission" :close-on-select="false" :clear-on-select="false" :preserve-search="true" track-by="permission"></multiselect>
        </div>
      </div><br>
      <div class="text-right">
        <button type="submit" class="btn btn-primary btn-lg">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>

import axios from '@/ajax/axios'
import user from "@/ajax/user";
import moment from 'moment';
// import Datepicker from 'vuejs-datepicker';
import myMixin from '@/mixins/Permission'
import UnauthorizedPage from '@/views/Layouts/UnauthorizedPage.vue';
import {Multiselect} from 'vue-multiselect';

import Vue from "vue";

Vue.component('multiselect', Multiselect);

export default {
  name: "AddReports",
  mixins: [myMixin],
  components:
  {
    Multiselect
  },
  data: function () {
    return {
      role:{
        name:"",
        description:"",
        permissions_id:[]
      },
      value:[],
      permissions: window.localStorage.getItem('permissions'),
      permissions:[]
    };
  },
  mounted(){
    this.getPermissionDropdown();
    // this.getRolesDropdown();
  },
  methods: {
    getPermissionDropdown(){
      user.getUserPermissionDropdown()
      .then(result=>{
        let arr=[];
        this.permissions=result.data.data;
        // console.log(this.permissions);
        this.permissions.forEach(obj=>{
          arr.push({id:obj.id,permission:obj.permission});
        });
        this.permissions=arr;
        // console.log(this.permissions);
      })
    },
    getRolesDropdown(){
      user.getUserRoleDropdown().then(request=>{
        this.role.roles_id=request.data.data.length;
      });
    },
    createRole(){
      // console.log(this.value);
      this.value.forEach(obj=>this.role.permissions_id.push(obj.id));
      user.addRole(this.role).then(response=>{
        alert("User added succesfully......");
        window.location.reload();
      });
    }
  }
}
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}

.disableButton {
  opacity: 0.5;
  pointer-events: none;
}

.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
